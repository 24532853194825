import { render, staticRenderFns } from "./contacts-manage.vue?vue&type=template&id=a123e532&scoped=true"
import script from "./contacts-manage.vue?vue&type=script&lang=js"
export * from "./contacts-manage.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/components/btn-bottom.scss?vue&type=style&index=0&id=a123e532&prod&lang=scss&scoped=true&external"
import style1 from "./contacts-manage.vue?vue&type=style&index=1&id=a123e532&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a123e532",
  null
  
)

export default component.exports